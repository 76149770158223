<template>
  <BaseSlugPage :filters="filters" />
</template>

<script setup>
import BaseSlugPage from "~/nuxt_modules/blog/ui/components/BaseSlugPage.vue";

definePageMeta({
  key: "postsByTag",
});

const route = useRoute();

const filters = computed(() => {
  return {
    tags: {
      slug: route.params.tagSlug,
    },
  };
});
</script>

<style scoped lang="scss"></style>
